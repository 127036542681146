import { AuthActionTypes, AuthState, AuthAction } from '../../types/auth';

const initialState: AuthState = {
  isAuth: false,
  loading: false,
  uuid: null,
  error: null,
};

export const authReducer = (
  state = initialState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case AuthActionTypes.USER_IS_AUTH:
      return {
        loading: false,
        error: null,
        isAuth: action.payload,
        uuid: null,
      };
    case AuthActionTypes.REGISTRATION_USER:
      return {
        loading: true,
        error: null,
        isAuth: false,
        uuid: null,
      };
    case AuthActionTypes.REGISTRATION_USER_SUCCESS:
      return {
        loading: false,
        error: null,
        isAuth: action.payload,
        uuid: null,
      };
    case AuthActionTypes.REGISTRATION_USER_ERROR:
      return {
        loading: false,
        error: action.payload,
        isAuth: false,
        uuid: null,
      };
    case AuthActionTypes.SMS_CONFIRMATIONS:
      return {
        loading: true,
        error: null,
        isAuth: false,
        uuid: null,
      };
    case AuthActionTypes.SMS_CONFIRMATIONS_SUCCESS:
      return {
        loading: false,
        error: null,
        isAuth: false,
        uuid: action.payload,
      };
    case AuthActionTypes.SMS_CONFIRMATIONS_ERROR:
      return {
        loading: false,
        error: action.payload,
        isAuth: false,
        uuid: null,
      };
    case AuthActionTypes.LOGIN_USER:
      return {
        loading: true,
        error: null,
        isAuth: false,
        uuid: null,
      };
    case AuthActionTypes.LOGIN_USER_SUCCESS:
      return {
        loading: false,
        error: null,
        isAuth: action.payload,
        uuid: null,
      };
    case AuthActionTypes.LOGIN_USER_ERROR:
      return {
        loading: false,
        error: action.payload,
        isAuth: false,
        uuid: null,
      };
    case AuthActionTypes.LOGOUT_USER_SUCCESS:
      return {
        loading: false,
        error: null,
        isAuth: false,
        uuid: null,
      };
    case AuthActionTypes.CLEAR_MESSAGE_AUTH:
      return {
        loading: false,
        error: null,
        isAuth: false,
        uuid: null,
      };
    default:
      return state;
  }
};
