export enum RestaurantActionTypes {
  FETCH_RESTAURANTS = 'FETCH_RESTAURANTS',
  FETCH_RESTAURANTS_SUCCESS = 'FETCH_RESTAURANTS_SUCCESS',
  FETCH_RESTAURANTS_ERROR = 'FETCH_RESTAURANTS_ERROR',
  SEARCH_RESTAURANTS = 'SEARCH_RESTAURANTS',
  SEARCH_RESTAURANTS_SUCCESS = 'SEARCH_RESTAURANTS_SUCCESS',
  SEARCH_RESTAURANTS_ERROR = 'SEARCH_RESTAURANTS_ERROR',
}

export interface RestaurantState {
  restaurants: IRestaurant;
  loading: boolean;
  error: string | null;
}

export interface IRestaurants {
  city: string;
  genre: string;
  id: number;
  idRestaurant: string;
  name: string;
  region: string;
}

export interface IRestaurant {
  totalItemsCount: number;
  pageIndex: number;
  data: IRestaurants[];
}

interface FetchRestaurantsAction {
  type: RestaurantActionTypes.FETCH_RESTAURANTS;
}
interface FetchRestaurantsSuccessAction {
  type: RestaurantActionTypes.FETCH_RESTAURANTS_SUCCESS;
  payload: any;
}
interface FetchRestaurantsErrorAction {
  type: RestaurantActionTypes.FETCH_RESTAURANTS_ERROR;
  payload: string;
}
interface SearchRestaurantsAction {
  type: RestaurantActionTypes.SEARCH_RESTAURANTS;
}
interface SearchRestaurantsSuccessAction {
  type: RestaurantActionTypes.SEARCH_RESTAURANTS_SUCCESS;
  payload: any;
}
interface SearchRestaurantsErrorAction {
  type: RestaurantActionTypes.SEARCH_RESTAURANTS_ERROR;
  payload: string;
}

export interface ISearchQuery {
  pageIndex: number;
  pageSize: number;
  region?: string;
  genre?: string;
  date?: string;
  timeFrom?: string;
  timeTo?: string;
  guestCount?: string;
}

export type RestaurantAction =
  | FetchRestaurantsAction
  | FetchRestaurantsSuccessAction
  | FetchRestaurantsErrorAction
  | SearchRestaurantsAction
  | SearchRestaurantsSuccessAction
  | SearchRestaurantsErrorAction;
