import { combineReducers } from 'redux';
import { authReducer } from './authReducer';
import { restaurantReducer } from './restaurantReducer';
import { slotReducer } from './slotReducer';

export const rootReducer = combineReducers({
  auth: authReducer,
  restaurant: restaurantReducer,
  slot: slotReducer,
});

export type RootReducer = ReturnType<typeof rootReducer>;
