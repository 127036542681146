import React, { FC, useRef } from 'react';

import { Portal } from '../Portal';

import classes from './styles.module.scss';

interface IModal {
  open: boolean;
  onClose(): void;
}

export const Modal: FC<IModal> = ({ open, onClose, children }) => {
  const backdrop = useRef(null);
  if (!open) {
    return null;
  }

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    if (backdrop.current === e.target) {
      onClose();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      onClose();
    }
  };

  return (
    <Portal>
      <div
        className={classes.Backdrop}
        ref={backdrop}
        onClick={onClick}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
      >
        <div className={classes.Content}>{children}</div>
      </div>
    </Portal>
  );
};
