import React, { FC, useState } from 'react';
import { ValueType } from 'react-select';
import DatePicker from 'react-datepicker';

import moment from 'moment';

import {
  REGION,
  GENRE,
  TIME_FROM,
  TIME_TO,
  COUNT_GUEST,
  SelectOption,
} from '../../constants/selectItems';
import { customStyles } from '../../constants/selectConfig';

import SelectField from '../Form/SelectField';

import 'react-datepicker/dist/react-datepicker.css';

import classes from './styles.module.scss';

type DateType = Date | null;

type SelectType = SelectOption | null;

interface ISearchItem {
  region: SelectType;
  genre: SelectType;
  timeFrom: SelectType;
  timeTo: SelectType;
  guestCount: SelectType;
}

interface ISearchRestaurants {
  searchRestaurant(restaurant: any): void;
  setSearchQuery(query: null): void;
}

const SearchRestaurants: FC<ISearchRestaurants> = ({
  searchRestaurant,
  setSearchQuery,
}) => {
  const [selectedDate, setDate] = useState<DateType>(null);
  const [state, setState] = useState<ISearchItem>({
    region: null,
    genre: null,
    timeFrom: null,
    timeTo: null,
    guestCount: null,
  });
  const [isSearch, setIsSearch] = useState<boolean>(false);

  const onChange = (option: ValueType<SelectOption, false>) => {
    setIsSearch(true);
    setState((prevState) => ({
      ...prevState,
      [option!.name]: option,
    }));
  };
  const onChangeDate = (date: DateType) => {
    setIsSearch(true);
    setDate(date);
  };

  const onSearch = () => {
    searchRestaurant({
      region: state.region ? state.region.value : '',
      genre: state.genre ? state.genre.value : '',
      timeFrom: state.timeFrom ? state.timeFrom.value : '',
      timeTo: state.timeTo ? state.timeTo.value : '',
      guestCount: state.guestCount ? state.guestCount.value : '',
      date: selectedDate ? moment(selectedDate).format('YYYY-MM-DD') : '',
    });
  };

  const cancel = () => {
    setState({
      region: null,
      genre: null,
      timeFrom: null,
      timeTo: null,
      guestCount: null,
    });
    setDate(null);
    setIsSearch(false);
    setSearchQuery(null);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      setState({
        region: null,
        genre: null,
        timeFrom: null,
        timeTo: null,
        guestCount: null,
      });
      setDate(null);
      setIsSearch(false);
      setSearchQuery(null);
    }
  };

  return (
    <div className={classes.SearchRestaurants}>
      <div className={classes.searchContainer}>
        <div className={classes.cancel}>
          <span
            onClick={cancel}
            className={isSearch ? classes.show : ''}
            onKeyDown={handleKeyDown}
            role="button"
            tabIndex={0}
          >
            +
          </span>
        </div>
        <div className={classes.region}>
          <SelectField
            width="135px"
            value={state.region}
            name="region"
            onChange={onChange}
            options={REGION}
            placeholder="Region"
            customStyles={customStyles}
          />
        </div>
        <div className={classes.genre}>
          <SelectField
            width="135px"
            value={state.genre}
            name="genre"
            onChange={onChange}
            options={GENRE}
            placeholder="Genre"
            customStyles={customStyles}
          />
        </div>
        <DatePicker
          dateFormat="yyyy/MM/dd"
          minDate={new Date()}
          showDisabledMonthNavigation
          selected={selectedDate}
          onChange={onChangeDate}
          className={classes.date}
          calendarClassName={classes.calendar}
          placeholderText="Date"
        />
        <div className={classes.timeContainer}>
          <div>
            <SelectField
              width="85px"
              value={state.timeFrom}
              name="timeFrom"
              onChange={onChange}
              options={TIME_FROM}
              placeholder="From"
              customStyles={customStyles}
            />
          </div>
          <div className={classes.delimiter}>-</div>
          <div>
            <SelectField
              width="85px"
              value={state.timeTo}
              name="timeTo"
              onChange={onChange}
              options={TIME_TO}
              placeholder="To"
              customStyles={customStyles}
            />
          </div>
        </div>
        <div className={classes.countGuest}>
          <SelectField
            width="100px"
            value={state.guestCount}
            name="guestCount"
            onChange={onChange}
            options={COUNT_GUEST}
            placeholder="Guests"
            customStyles={customStyles}
          />
        </div>
        <div>
          <button
            type="button"
            className={classes.btnSearch}
            onClick={onSearch}
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchRestaurants;
