import axios from 'axios';

import { AuthResponse } from '../types/auth';

import { API_URL } from '../constants/main';

import {
  getTokenData,
  saveAuthData,
  clearAuthData,
  getAuthData,
} from '../helpers/authStorage';

const $api = axios.create({
  withCredentials: true,
  baseURL: API_URL,
});

/* eslint-disable no-param-reassign */
$api.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${getTokenData()}`;
  return config;
});

/* eslint-disable no-param-reassign */
$api.interceptors.response.use(
  (config) => {
    return config;
  },
  // eslint-disable-next-line consistent-return
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      error.config &&
      // eslint-disable-next-line no-underscore-dangle
      !error.config._isRetry
    ) {
      try {
        // eslint-disable-next-line no-underscore-dangle
        originalRequest._isRetry = true;

        const response = await axios.get<AuthResponse>(
          `${API_URL}/auth/auth-token-refresh`,
          {
            withCredentials: true,
          }
        );
        saveAuthData(response.data);
        return $api.request(originalRequest);
      } catch (e) {
        /* clearAuthData(); */
        console.log('No auth');
      }
    }
    throw error;
  }
);

export default $api;
