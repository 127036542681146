import React, { useState, useEffect } from 'react';

export const useTime = () => {
  const currentDate = new Date().toLocaleTimeString();
  const [time, setTime] = useState(currentDate);

  const setClock = () => {
    const currentTime = new Date().toLocaleTimeString();
    setTime(currentTime);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setClock();
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return {
    time,
  };
};
