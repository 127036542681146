import { AxiosResponse } from 'axios';
import $api from '../http';
import {
  AuthResponse,
  IRegistration,
  ISMSConfirmationResponse,
} from '../types/auth';

export default class AuthService {
  static async registration(
    props: IRegistration
  ): Promise<AxiosResponse<AuthResponse>> {
    return $api.post<AuthResponse>('/auth/registration', { ...props });
  }

  static async login(
    email: string,
    password: string
  ): Promise<AxiosResponse<AuthResponse>> {
    return $api.post<AuthResponse>('/auth/login', { email, password });
  }

  static async smsConfirmations(
    phoneCountry: string,
    phoneLocalNumber: string
  ): Promise<AxiosResponse<ISMSConfirmationResponse>> {
    return $api.post<ISMSConfirmationResponse>('/auth/sms-confirmations', {
      phoneCountry,
      phoneLocalNumber,
    });
  }
}
