import React, { useState, useEffect } from 'react';

import Loader from '../../components/Loader';
import Header from '../../components/Header';
import { useActions } from '../../hooks/useActions';
import { useTypesSelector } from '../../hooks/useTypesSelector';
import RestaurantsList from '../../components/RestaurantsList';
import SearchRestaurants from '../../components/SearchRestaurants';

import { DEFAULT_PAGE_SIZE } from '../../constants/main';

import classes from './styles.module.scss';

type SearchQueryType = ISearch | null;

interface ISearch {
  date?: string;
  genre?: string;
  guestCount?: string;
  region?: string;
  timeFrom?: string;
  timeTo?: string;
}

const HomePage = () => {
  const { data, pageIndex, totalItemsCount } = useTypesSelector(
    (state) => state.restaurant.restaurants
  );
  const { loading } = useTypesSelector((state) => state.restaurant);
  const { fetchRestaurants, searchRestaurants, clearMessageRestaurant } =
    useActions();

  const [searchQuery, setSearchQuery] = useState<SearchQueryType>(null);
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const fetchRestaurantsOnPage = (newPageIndex: number) => {
    // eslint-disable-next-line no-unused-expressions
    searchQuery
      ? searchRestaurants({
          ...searchQuery,
          pageIndex: newPageIndex,
          pageSize: DEFAULT_PAGE_SIZE,
        })
      : fetchRestaurants({
          pageIndex: newPageIndex,
          pageSize: DEFAULT_PAGE_SIZE,
        });
  };
  const search = (query: ISearch) => {
    setSearchQuery({ ...query });
    setIsSearch(true);
  };
  useEffect(() => {
    clearMessageRestaurant();
    fetchRestaurantsOnPage(pageIndex || 0);
  }, []);

  useEffect(() => {
    if (isSearch) {
      fetchRestaurantsOnPage(0);
    }
  }, [searchQuery]);

  return (
    <div className={classes.HomePage}>
      <Header />
      <SearchRestaurants
        searchRestaurant={search}
        setSearchQuery={setSearchQuery}
      />
      {(loading && !data.length) || (loading && !!searchQuery) ? (
        <Loader />
      ) : (
        <div className={classes.restaurantsContainer}>
          {data && data.length ? (
            <RestaurantsList
              restaurants={data}
              pageIndex={pageIndex}
              totalItemsCount={totalItemsCount}
              onPageChange={fetchRestaurantsOnPage}
            />
          ) : (
            <div className={classes.noRestaurants}>No restaurants</div>
          )}
        </div>
      )}
    </div>
  );
};

export default HomePage;
