import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Header from '../../components/Header';
import PlacesList, { ISlotInfo } from '../../components/PlacesList';
import Loader from '../../components/Loader';
import Alert from '../../components/Alert';

import { useTypesSelector } from '../../hooks/useTypesSelector';

import classes from './styles.module.scss';
import { useActions } from '../../hooks/useActions';
import { useTime } from '../../hooks/useTime';

interface IMatch {
  params: {
    id: string;
  };
}

interface IRestaurantPage {
  match: IMatch;
}

const RestaurantPage: FC<IRestaurantPage> = ({ match }) => {
  const history = useHistory();

  const { fetchRestaurant, clearMessageRestaurant, checkFreeSlot } =
    useActions();

  const { restaurantInfo, slots, loading, freeSlot, error } = useTypesSelector(
    (state) => state.slot
  );

  const { time } = useTime();

  useEffect(() => {
    fetchRestaurant(match.params.id);
  }, [match.params.id]);

  const checkSlot = (slot: ISlotInfo) => {
    checkFreeSlot(slot);
  };

  useEffect(() => {
    if (freeSlot?.status) {
      history.push('/details');
    }
  }, [freeSlot]);

  return (
    <>
      <div className={classes.AvailablePlacesPage}>
        <Header />
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className={classes.titleContainer}>
              <div className={classes.title}>
                {restaurantInfo && restaurantInfo.name}
              </div>
              <div className={classes.timer}>{time}</div>
            </div>
            <div className={classes.placesContainer}>
              {slots && slots.length ? (
                <PlacesList places={slots} checkSlot={checkSlot} />
              ) : (
                <div className={classes.noSlots}>No slots available</div>
              )}
            </div>
          </>
        )}
      </div>
      <Alert message={error} clearMessage={clearMessageRestaurant} />
    </>
  );
};

export default RestaurantPage;
