import React, { FC, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { hasAuthData } from '../helpers/authStorage';
import { useActions } from '../hooks/useActions';
import { useTypesSelector } from '../hooks/useTypesSelector';

import LoginPage from '../pages/Auth/Login';
/* import SignUpPage from '../pages/Auth/SignUpPage'; */
import RestaurantPage from '../pages/RestaurantPage';
import PlaceDetailPage from '../pages/PlaceDetail';
import HomePage from '../pages/HomePage';

const Routes: FC = () => {
  const { authUser } = useActions();
  const isAuth = useTypesSelector((state) => state.auth.isAuth);

  useEffect(() => {
    authUser(hasAuthData());
  }, []);

  if (isAuth) {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/restaurant/:id" component={RestaurantPage} />
          <Route exact path="/details" component={PlaceDetailPage} />
        </Switch>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={LoginPage} />
        {/* <Route exact path="/signup" component={SignUpPage} /> */}
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
