import React, { FC, useEffect } from 'react';

import classnames from 'classnames';

import classes from './styles.module.scss';

interface IAlert {
  message: string | null;
  clearMessage(): void;
}

const Alert: FC<IAlert> = ({ message, clearMessage }) => {
  useEffect(() => {
    setTimeout(() => {
      if (message) clearMessage();
    }, 4000);
  }, [message]);

  return (
    <div className={classnames(classes.Alert, message && classes.show)}>
      <p className={classes.message}>{message}</p>
    </div>
  );
};

export default Alert;
