export enum AuthActionTypes {
  USER_IS_AUTH = 'USER_IS_AUTH',
  REGISTRATION_USER = 'REGISTRATION_USER',
  REGISTRATION_USER_SUCCESS = 'REGISTRATION_USER_SUCCESS',
  REGISTRATION_USER_ERROR = 'REGISTRATION_USER_ERROR',
  LOGIN_USER = 'LOGIN_USER',
  LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS',
  LOGIN_USER_ERROR = 'LOGIN_USER_ERROR',
  LOGOUT_USER = 'LOGOUT_USER',
  LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS',
  LOGOUT_USER_ERROR = 'LOGOUT_USER_ERROR',
  SMS_CONFIRMATIONS = 'SMS_CONFIRMATIONS',
  SMS_CONFIRMATIONS_SUCCESS = 'SMS_CONFIRMATIONS_SUCCESS',
  SMS_CONFIRMATIONS_ERROR = 'SMS_CONFIRMATIONS_ERROR',
  SMS_CONFIRMATIONS_CONFIRM = 'SMS_CONFIRMATIONS_CONFIRM',
  SMS_CONFIRMATIONS_CONFIRM_SUCCESS = 'SMS_CONFIRMATIONS_CONFIRM_SUCCESS',
  SMS_CONFIRMATIONS_CONFIRM_ERROR = 'SMS_CONFIRMATIONS_CONFIRM_ERROR',
  CLEAR_MESSAGE_AUTH = 'CLEAR_MESSAGE_AUTH',
}

export interface AuthResponse {
  accessToken: string;
  refreshToken: string;
  userId: number;
}

export interface ISMSConfirmationResponse {
  uuid: string;
}

export interface AuthState {
  isAuth: boolean;
  loading: boolean;
  error: string | null;
  uuid: ISMSConfirmationResponse | null;
}

interface UserISAuth {
  type: AuthActionTypes.USER_IS_AUTH;
  payload: boolean;
}

interface RegistrationUserAction {
  type: AuthActionTypes.REGISTRATION_USER;
}
interface RegistrationUserSuccessAction {
  type: AuthActionTypes.REGISTRATION_USER_SUCCESS;
  payload: boolean;
}
interface RegistrationUserErrorAction {
  type: AuthActionTypes.REGISTRATION_USER_ERROR;
  payload: string;
}

interface LoginUserAction {
  type: AuthActionTypes.LOGIN_USER;
}
interface LoginUserSuccessAction {
  type: AuthActionTypes.LOGIN_USER_SUCCESS;
  payload: boolean;
}
interface LoginUserErrorAction {
  type: AuthActionTypes.LOGIN_USER_ERROR;
  payload: string;
}

interface LogoutUserAction {
  type: AuthActionTypes.LOGOUT_USER;
}
interface LogoutUserSuccessAction {
  type: AuthActionTypes.LOGOUT_USER_SUCCESS;
}
interface LogoutUserErrorAction {
  type: AuthActionTypes.LOGOUT_USER_ERROR;
}

interface SMSConfirmationAction {
  type: AuthActionTypes.SMS_CONFIRMATIONS;
}
interface SMSConfirmationSuccessAction {
  type: AuthActionTypes.SMS_CONFIRMATIONS_SUCCESS;
  payload: ISMSConfirmationResponse;
}
interface SMSConfirmationErrorAction {
  type: AuthActionTypes.SMS_CONFIRMATIONS_ERROR;
  payload: string;
}

interface SMSConfirmationConfirmAction {
  type: AuthActionTypes.SMS_CONFIRMATIONS_CONFIRM;
}
interface SMSConfirmationConfirmSuccessAction {
  type: AuthActionTypes.SMS_CONFIRMATIONS_CONFIRM_SUCCESS;
}
interface SMSConfirmationConfirmErrorAction {
  type: AuthActionTypes.SMS_CONFIRMATIONS_CONFIRM_ERROR;
}

interface ClearMessageAction {
  type: AuthActionTypes.CLEAR_MESSAGE_AUTH;
}

export interface IUser {
  id: number;
  email: string;
}

export interface IRegistration {
  email: string;
  password: string;
  firstName: string;
  firstNameLatin: string;
  lastName: string;
  lastNameLatin: string;
  phone: string;
  residence: string;
  language: string;
  agree: boolean;
}

export interface ISMSConfirmation {
  phoneCountry: string;
  phoneLocalNumber: string;
}

export interface ILogin {
  email: string;
  password: string;
}

export type AuthAction =
  | UserISAuth
  | RegistrationUserAction
  | RegistrationUserSuccessAction
  | RegistrationUserErrorAction
  | LoginUserAction
  | LoginUserSuccessAction
  | LoginUserErrorAction
  | LogoutUserAction
  | LogoutUserSuccessAction
  | LogoutUserErrorAction
  | SMSConfirmationAction
  | SMSConfirmationSuccessAction
  | SMSConfirmationErrorAction
  | SMSConfirmationConfirmAction
  | SMSConfirmationConfirmSuccessAction
  | SMSConfirmationConfirmErrorAction
  | ClearMessageAction;
