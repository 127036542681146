import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTimer } from 'react-timer-hook';
import { useActions } from '../../hooks/useActions';

const Timer = ({ expiryTimestamp }: any) => {
  const history = useHistory();
  const { clearMessageRestaurant } = useActions();
  const { seconds, minutes, start } = useTimer({
    expiryTimestamp,
    onExpire: () => {
      clearMessageRestaurant();
      history.push('/');
    },
  });

  useEffect(() => {
    start();
  }, [start]);

  return (
    <div>
      <span>{minutes}</span>:<span>{seconds}</span>
    </div>
  );
};

export default Timer;
