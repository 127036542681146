import React, { FC } from 'react';

import Select, { components, ValueType } from 'react-select';

import { SelectOption } from '../../../constants/selectItems';

import classes from './styles.module.scss';

interface ISelectField {
  width: string;
  height?: string;
  value: SelectOption | null;
  name: string;
  onChange(option: ValueType<SelectOption, false>): void;
  placeholder: string;
  options: SelectOption[];
  customStyles: any;
}

const DropdownIndicator = (props: any) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.DropdownIndicator {...props}>
      <div className={classes.DropdownIndicator} />
    </components.DropdownIndicator>
  );
};

const SelectField: FC<ISelectField> = ({
  width,
  height,
  value,
  name,
  onChange,
  placeholder,
  options,
  customStyles,
}) => {
  return (
    <>
      <Select
        components={{ DropdownIndicator }}
        styles={customStyles}
        className="react-select"
        classNamePrefix="react-select"
        width={width}
        height={height}
        value={value}
        name={name}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        isSearchable={false}
      />
    </>
  );
};

export default SelectField;
