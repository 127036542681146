import React, { FC, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';

import Logo from '../Logo';

import useOnClickOutside from '../../hooks/useOnClickOutside';

import classes from './styles.module.scss';
import { useActions } from '../../hooks/useActions';

const Header: FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [isLogoutVisible, setIsLogoutVisible] = useState(false);
  const history = useHistory();

  const { logoutUser } = useActions();
  const handleClickOutside = () => {
    setIsLogoutVisible(() => false);
  };

  useOnClickOutside(ref, handleClickOutside);

  const toggleLogoutDropDown = () => {
    setIsLogoutVisible((prevState) => !prevState);
  };

  const onHomePage = () => {
    history.push('/');
  };

  const logoutHandler = () => {
    logoutUser();
    history.push('/');
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      history.push('/');
    }
  };
  const handleKeyDownLogout = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      setIsLogoutVisible(() => false);
    }
  };

  return (
    <div className={classes.Header}>
      <div
        className={classes.logoContainer}
        onClick={() => onHomePage()}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
      >
        <Logo miniLogo />
        <div className={classes.logoText}>akasete</div>
      </div>

      <div className={classes.account}>
        <div ref={ref} className={classes.dropdown}>
          <button
            className={classes.dropbtn}
            type="button"
            onClick={toggleLogoutDropDown}
          >
            My account
          </button>
          <div
            className={classnames(
              isLogoutVisible && classes.isShow,
              classes.dropdownContent
            )}
          >
            <div
              onClick={logoutHandler}
              onKeyDown={handleKeyDownLogout}
              role="button"
              tabIndex={0}
            >
              Log out
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
