export const customStyles = {
  control: (styles: any, state: any) => ({
    ...styles,
    backgroundColor: '#f4f4f4',
    width: state.selectProps.width,
    borderColor: ' #f4f4f4',
    boxShadow: 'none',
    cursor: 'pointer',
    '&:hover': {
      borderColor: ' #f4f4f4',
    },
  }),
  option: (styles: any, state: any) => {
    return {
      ...styles,
      color: state.isSelected ? '#f4f4f4' : '#373A3C',
      backgroundColor: state.isSelected ? '#373A3C' : '#f4f4f4',
      cursor: 'pointer',
      '&:hover': {
        color: '#373A3C',
        backgroundColor: '#d1d1d1',
      },
    };
  },
  menuList: (base: any) => ({
    ...base,
    height: '200px',
    borderRadius: '5px',
    scrollbarСolor: '#373A3C #f4f4f4',
    scrollbarWidth: 'thin',
    '::-webkit-scrollbar': {
      width: '7px',
      backgroundColor: '#373A3C',
    },
    '::-webkit-scrollbar-track': {
      backgroundColor: '#f4f4f4',
      borderTopRightRadius: '5px',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#373A3C',
      borderRadius: '5px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#53575a',
    },
  }),
  menu: (styles: any) => {
    return {
      ...styles,
      backgroundColor: '#f4f4f4',
      borderRadius: '5px',
    };
  },
  indicatorSeparator: (styles: any) => {
    return {
      ...styles,
      display: 'none',
    };
  },
};

export const customStylesForm = {
  control: (styles: any, state: any) => ({
    ...styles,
    backgroundColor: '#fff',
    width: state.selectProps.width,
    borderColor: '#CCCCCC',
    boxShadow: 'none',
    cursor: 'pointer',
    fontSize: '14px',
    '&:hover': {
      borderColor: ' #f4f4f4',
    },
  }),
  option: (styles: any, state: any) => {
    return {
      ...styles,
      color: state.isSelected ? '#f4f4f4' : '#373A3C',
      backgroundColor: state.isSelected ? '#373A3C' : '#fff',
      cursor: 'pointer',
      '&:hover': {
        color: '#373A3C',
        backgroundColor: '#d1d1d1',
      },
    };
  },
  menuList: (base: any, state: any) => {
    return {
      ...base,
      height: state.selectProps.height,
      borderRadius: '5px',
      scrollbarСolor: '#373A3C #f4f4f4',
      scrollbarWidth: 'thin',
      '::-webkit-scrollbar': {
        width: '7px',
        backgroundColor: '#373A3C',
        borderTopRightRadius: '5px',
      },
      '::-webkit-scrollbar-track': {
        backgroundColor: '#f4f4f4',
        borderTopRightRadius: '5px',
      },
      '::-webkit-scrollbar-thumb': {
        backgroundColor: '#373A3C',
        borderRadius: '5px',
      },
      '::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#53575a',
      },
    };
  },
  menu: (styles: any) => {
    return {
      ...styles,
      backgroundColor: '#fff',
      borderRadius: '5px',
    };
  },
  indicatorSeparator: (styles: any) => {
    return {
      ...styles,
      display: 'none',
    };
  },
};
