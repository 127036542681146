import React, { FC } from 'react';

import classnames from 'classnames';

import classes from './styles.module.scss';

interface IButton {
  label: string;
  handler(e: any): void;
  isDisabled?: boolean;
  isLogin?: boolean;
  isContinue?: boolean;
  isLoading?: boolean;
  isForm?: boolean;
}

const Button: FC<IButton> = ({
  label,
  handler,
  isDisabled,
  isLogin,
  isContinue,
  isLoading,
  isForm,
}) => {
  return (
    <button
      type="button"
      disabled={isDisabled}
      onClick={handler}
      className={classnames(
        classes.Button,
        isLogin && classes.signIn,
        isContinue && classes.isContinue,
        isLoading && classes.buttonLoading,
        isForm && classes.buttonInForm
      )}
    >
      <span className={classes.btnText}>{label}</span>
    </button>
  );
};

export default Button;
