import React, { ChangeEvent, FC } from 'react';

import classes from './styles.module.scss';

interface ICheckBox {
  label: string;
  checked: boolean;
  name: string;
  onChange(e: string | ChangeEvent<any>): void;
}

const CheckBox: FC<ICheckBox> = ({ label, checked, name, onChange }) => {
  return (
    /* eslint-disable */
    <label className={classes.CheckBox}>
      <input type="checkbox" checked={checked} name={name} onChange={onChange} />
      <span className={classes.checkmark} />
      {label}
    </label>
    /* eslint-disable */
  );
}

export default CheckBox;