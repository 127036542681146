import { Dispatch } from 'redux';
import {
  RestaurantAction,
  RestaurantActionTypes,
  ISearchQuery,
} from '../../types/restaurants';
import RestaurantService from '../../services/RestaurantService';
import { SlotAction, SlotActionTypes } from '../../types/slots';
import { IReservation, ISlotInfo } from '../../components/PlacesList';

export const fetchRestaurants = ({ pageIndex, pageSize }: ISearchQuery) => {
  return async (dispatch: Dispatch<RestaurantAction>) => {
    try {
      dispatch({ type: RestaurantActionTypes.FETCH_RESTAURANTS });
      const response = await RestaurantService.fetchRestaurants({
        pageIndex,
        pageSize,
      });

      dispatch({
        type: RestaurantActionTypes.FETCH_RESTAURANTS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: RestaurantActionTypes.FETCH_RESTAURANTS_ERROR,
        payload: error.message,
      });
    }
  };
};

export const searchRestaurants = ({
  pageIndex,
  pageSize,
  genre,
  date,
  region,
  timeFrom,
  timeTo,
  guestCount,
}: ISearchQuery) => {
  return async (dispatch: Dispatch<RestaurantAction>) => {
    try {
      dispatch({ type: RestaurantActionTypes.SEARCH_RESTAURANTS });
      const response = await RestaurantService.searchRestaurants({
        genre,
        region,
        timeFrom,
        timeTo,
        date,
        guestCount,
        pageIndex,
        pageSize,
      });

      dispatch({
        type: RestaurantActionTypes.SEARCH_RESTAURANTS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: RestaurantActionTypes.SEARCH_RESTAURANTS_ERROR,
        payload: error.message,
      });
    }
  };
};

export const fetchRestaurant = (id: string) => {
  return async (dispatch: Dispatch<SlotAction>) => {
    try {
      dispatch({ type: SlotActionTypes.FETCH_SLOTS });
      const response = await RestaurantService.fetchRestaurant(id);

      dispatch({
        type: SlotActionTypes.FETCH_SLOTS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: SlotActionTypes.FETCH_SLOTS_ERROR,
        payload: error.message,
      });
    }
  };
};

export const createReservation = ({ reservation, password }: IReservation) => {
  return async (dispatch: Dispatch<SlotAction>) => {
    try {
      dispatch({ type: SlotActionTypes.RESERVATION_SLOTS });
      const response = await RestaurantService.makeReservation(
        reservation,
        password
      );

      if (response.data.message) {
        dispatch({
          type: SlotActionTypes.RESERVATION_SLOTS_ERROR,
          payload: response.data.message,
        });
      } else {
        dispatch({
          type: SlotActionTypes.RESERVATION_SLOTS_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: SlotActionTypes.RESERVATION_SLOTS_ERROR,
        payload: error.response.data?.message,
      });
    }
  };
};

export const checkFreeSlot = (slot: ISlotInfo) => {
  return async (dispatch: Dispatch<SlotAction>) => {
    try {
      dispatch({ type: SlotActionTypes.CHECK_SLOT });
      const response = await RestaurantService.checkFreeSlot(slot);

      if (response.data.message) {
        dispatch({
          type: SlotActionTypes.CHECK_SLOT_ERROR,
          payload: response.data.message,
        });
      } else {
        dispatch({
          type: SlotActionTypes.CHECK_SLOT_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: SlotActionTypes.CHECK_SLOT_ERROR,
        payload: error.response.data?.message,
      });
    }
  };
};

export const clearMessageRestaurant = () => {
  return (dispatch: Dispatch<SlotAction>) => {
    dispatch({ type: SlotActionTypes.CLEAR_MESSAGE_SLOTS });
  };
};
