import { FC, useEffect } from 'react';
import ReactDOM from 'react-dom';

const parent = document.getElementById('modal');

export const Portal: FC = ({ children }) => {
  const el = document.createElement('div');

  useEffect(() => {
    if (parent) {
      parent.appendChild(el);
    }
  }, []);

  return parent ? ReactDOM.createPortal(children, parent) : null;
};
