import React, { FC } from 'react';

import classnames from 'classnames';

import classes from './styles.module.scss';

interface IInputField {
  placeholder: string;
  type: string;
  value: string;
  setValue(e: React.ChangeEvent<HTMLInputElement>): void;
  name: string;
  topField?: boolean;
  bottomField?: boolean;
}

const InputField: FC<IInputField> = ({
  placeholder,
  type,
  value,
  setValue,
  name,
  topField,
  bottomField,
}) => {
  return (
    <>
      <input
        className={classnames(
          classes.inputField,
          topField && classes.topField,
          bottomField && classes.bottomField
        )}
        type={type}
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={(e) => setValue(e)}
      />
    </>
  );
};

export default InputField;
