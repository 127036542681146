import React, { FC } from 'react';
import ReactPaginate from 'react-paginate';

import { DEFAULT_PAGE_SIZE } from '../../constants/main';

import Restaurant from '../Restaurant';

import paginateRight from '../../assets/images/paginateRight.svg';
import paginateLeft from '../../assets/images/paginateLeft.svg';

import classes from './styles.module.scss';
import { IRestaurants } from '../../types/restaurants';

interface IRestaurantsList {
  restaurants: IRestaurants[];
  pageIndex: number;
  totalItemsCount: number;
  onPageChange(newPageIndex: number): void;
}

interface ISelected {
  selected: number;
}

const RestaurantsList: FC<IRestaurantsList> = ({
  restaurants,
  pageIndex,
  totalItemsCount,
  onPageChange,
}) => {
  const pageCount = Math.ceil(totalItemsCount / DEFAULT_PAGE_SIZE);

  const changePage = (page: ISelected) => {
    if (pageIndex === page.selected) {
      return;
    }
    onPageChange(page.selected);
  };

  return (
    <div className={classes.PlacesList}>
      <table className={classes.table}>
        <thead>
          <tr className={classes.tableHeader}>
            <th className={classes.th}> </th>
            <th className={classes.th}>Name</th>
            <th className={classes.th}>Region</th>
            <th className={classes.th}>Genre</th>
          </tr>
        </thead>
        <tbody>
          {restaurants.map((item, index) => (
            <Restaurant key={item.id} restaurant={item} index={index + 1} />
          ))}
        </tbody>
      </table>
      <div className={classes.pagination}>
        <ReactPaginate
          previousClassName={classes.previous}
          nextClassName={classes.next}
          activeClassName={classes.paginationActive}
          pageCount={pageCount}
          initialPage={pageIndex}
          onPageChange={changePage}
          containerClassName={classes.paginationBttns}
          previousLabel={<img src={paginateLeft} alt="arrowLeft" />}
          nextLabel={<img src={paginateRight} alt="arrowRight" />}
          previousLinkClassName="previousBttn"
          nextLinkClassName="nextBttn"
          disabledClassName="paginationDisabled"
          pageRangeDisplayed={6}
          marginPagesDisplayed={4}
        />
      </div>
    </div>
  );
};

export default RestaurantsList;
