import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { IRestaurants } from '../../types/restaurants';

import classes from './styles.module.scss';

interface IRestaurantItem {
  restaurant: IRestaurants;
  index: number;
}

const RestaurantItem: FC<IRestaurantItem> = ({ restaurant, index }) => {
  const history = useHistory();

  return (
    <>
      <tr
        className={classes.tableRow}
        key={restaurant.id}
        onClick={() => history.push(`/restaurant/${restaurant.id}`)}
      >
        <td className={classes.td}>{index}</td>
        <td className={classes.td}>{restaurant.name}</td>
        <td className={classes.td}>{restaurant.city}</td>
        <td className={classes.td}>{restaurant.genre}</td>
      </tr>
    </>
  );
};

export default RestaurantItem;
