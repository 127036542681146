const REMEMBER_ME = 'rememberMe';
const EMAIL = 'email';

export const saveUserData = (values: any) => {
  localStorage.setItem(REMEMBER_ME, values.rememberMe);
  localStorage.setItem(EMAIL, values.rememberMe ? values.email : '');
};

export const getUserData = () => {
  const rememberMe = localStorage.getItem(REMEMBER_ME) === 'true';
  const email = rememberMe ? localStorage.getItem(EMAIL) : '';
  return { rememberMe, email };
};
