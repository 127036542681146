export interface SelectOption {
  value: string;
  label: string;
  name: string;
}

export const RESIDENCE: SelectOption[] = [
  { value: 'hokkaido', label: 'Hokkaido', name: 'residence' },
  { value: 'aomori', label: 'Aomori', name: 'residence' },
  { value: 'iwate', label: 'Iwate', name: 'residence' },
  { value: 'miyagi', label: 'Miyagi', name: 'residence' },
  { value: 'akita', label: 'Akita', name: 'residence' },
  { value: 'yamagata', label: 'Yamagata', name: 'residence' },
  { value: 'fukushima', label: 'Fukushima', name: 'residence' },
  { value: 'ibaraki', label: 'Ibaraki', name: 'residence' },
  { value: 'tochigi', label: 'Tochigi', name: 'residence' },
  { value: 'gunma', label: 'Gunma', name: 'residence' },
  { value: 'saitama', label: 'Saitama', name: 'residence' },
  { value: 'chiba', label: 'Chiba', name: 'residence' },
  { value: 'tokyo', label: 'Tokyo', name: 'residence' },
  { value: 'kanagawa', label: 'Kanagawa', name: 'residence' },
  { value: 'niigata', label: 'Niigata', name: 'residence' },
  { value: 'toyama', label: 'Toyama', name: 'residence' },
  { value: 'ishikawa', label: 'Ishikawa', name: 'residence' },
  { value: 'fukui', label: 'Fukui', name: 'residence' },
  { value: 'yamanashi', label: 'Yamanashi', name: 'residence' },
  { value: 'nagano', label: 'Nagano', name: 'residence' },
  { value: 'gifu', label: 'Gifu', name: 'residence' },
  { value: 'shizuoka', label: 'Shizuoka', name: 'residence' },
  { value: 'aichi', label: 'Aichi', name: 'residence' },
  { value: 'mie', label: 'Mie', name: 'residence' },
  { value: 'shiga', label: 'Shiga', name: 'residence' },
  { value: 'kyoto', label: 'Kyoto', name: 'residence' },
  { value: 'osaka', label: 'Osaka', name: 'residence' },
  { value: 'hyogo', label: 'Hyogo', name: 'residence' },
  { value: 'nara', label: 'Nara', name: 'residence' },
  { value: 'wakayama', label: 'Wakayama', name: 'residence' },
  { value: 'tottori', label: 'Tottori', name: 'residence' },
  { value: 'shimane', label: 'Shimane', name: 'residence' },
  { value: 'okayama', label: 'Okayama', name: 'residence' },
  { value: 'hiroshima', label: 'Hiroshima', name: 'residence' },
  { value: 'yamaguchi', label: 'Yamaguchi', name: 'residence' },
  { value: 'tokushima', label: 'Tokushima', name: 'residence' },
  { value: 'kagawa', label: 'Kagawa', name: 'residence' },
  { value: 'ehime', label: 'Ehime', name: 'residence' },
  { value: 'kochi', label: 'Kochi', name: 'residence' },
  { value: 'fukuoka', label: 'Fukuoka', name: 'residence' },
  { value: 'saga', label: 'Saga', name: 'residence' },
  { value: 'nagasaki', label: 'Nagasaki', name: 'residence' },
  { value: 'kumamoto', label: 'Kumamoto', name: 'residence' },
  { value: 'oita', label: 'Oita', name: 'residence' },
  { value: 'miyazaki', label: 'Miyazaki', name: 'residence' },
  { value: 'kagoshima', label: 'Kagoshima', name: 'residence' },
  { value: 'okinawa', label: 'Okinawa', name: 'residence' },
  { value: 'overseas', label: 'Overseas', name: 'residence' },
];

export const LANGUAGE: SelectOption[] = [
  { value: 'english', label: 'English', name: 'language' },
];
export const REGION: SelectOption[] = [
  { value: 'Hokkaido', label: 'Hokkaido', name: 'region' },
  { value: 'Tohoku', label: 'Tohoku', name: 'region' },
  { value: 'Kanto', label: 'Kanto', name: 'region' },
  { value: 'Chubu/Hokuriku', label: 'Chubu/Hokuriku', name: 'region' },
  { value: 'Kansai', label: 'Kansai', name: 'region' },
  { value: 'Chugoku', label: 'Chugoku', name: 'region' },
  { value: 'Shikoku', label: 'Shikoku', name: 'region' },
  { value: 'Kyushu', label: 'Kyushu', name: 'region' },
];

export const GENRE: SelectOption[] = [
  { value: 'sushi', label: 'Sushi', name: 'genre' },
  { value: 'tempura', label: 'Tempura', name: 'genre' },
  { value: 'ramen', label: 'Ramen', name: 'genre' },
  { value: 'japanese', label: 'Japanese, Kaiseki', name: 'genre' },
  { value: 'hotpot', label: 'Hotpot, Shabushabu', name: 'genre' },
  { value: 'izakaya', label: 'Izakaya, Bar', name: 'genre' },
  { value: 'fugu', label: 'Fugu (puffy fish)', name: 'genre' },
  { value: 'kani', label: 'Crab', name: 'genre' },
  {
    value: 'yakiniku',
    label: 'Japanese BBQ, Yakiniku',
    name: 'genre',
  },
  { value: 'steak', label: 'Steak', name: 'genre' },
  { value: 'yakitori', label: 'Yakitori', name: 'genre' },
  { value: 'italian', label: 'Italian', name: 'genre' },
  { value: 'french', label: 'French', name: 'genre' },
  { value: 'other_european', label: 'Other european', name: 'genre' },
  { value: 'innovative', label: 'Innovative', name: 'genre' },
  { value: 'ethnic', label: 'Ethnic', name: 'genre' },
  { value: 'chinese', label: 'Chinese', name: 'genre' },
  { value: 'unagi', label: 'Eel, Unagi', name: 'genre' },
  { value: 'cakes', label: 'Cakes, Sweets', name: 'genre' },
  {
    value: 'tonkatsu',
    label: 'Tonkatsu(Pork cutlet)',
    name: 'genre',
  },
  { value: 'others', label: 'Others', name: 'genre' },
];

export const TIME_FROM: SelectOption[] = [
  { value: '10:00', label: '10:00', name: 'timeFrom' },
  { value: '11:00', label: '11:00', name: 'timeFrom' },
  { value: '12:00', label: '12:00', name: 'timeFrom' },
  { value: '13:00', label: '13:00', name: 'timeFrom' },
  { value: '14:00', label: '14:00', name: 'timeFrom' },
  { value: '15:00', label: '15:00', name: 'timeFrom' },
  { value: '16:00', label: '16:00', name: 'timeFrom' },
  { value: '17:00', label: '17:00', name: 'timeFrom' },
  { value: '18:00', label: '18:00', name: 'timeFrom' },
  { value: '19:00', label: '19:00', name: 'timeFrom' },
  { value: '20:00', label: '20:00', name: 'timeFrom' },
  { value: '21:00', label: '21:00', name: 'timeFrom' },
  { value: '22:00', label: '22:00', name: 'timeFrom' },
];

export const TIME_TO: SelectOption[] = [
  { value: '10:00', label: '10:00', name: 'timeTo' },
  { value: '11:00', label: '11:00', name: 'timeTo' },
  { value: '12:00', label: '12:00', name: 'timeTo' },
  { value: '13:00', label: '13:00', name: 'timeTo' },
  { value: '14:00', label: '14:00', name: 'timeTo' },
  { value: '15:00', label: '15:00', name: 'timeTo' },
  { value: '16:00', label: '16:00', name: 'timeTo' },
  { value: '17:00', label: '17:00', name: 'timeTo' },
  { value: '18:00', label: '18:00', name: 'timeTo' },
  { value: '19:00', label: '19:00', name: 'timeTo' },
  { value: '20:00', label: '20:00', name: 'timeTo' },
  { value: '21:00', label: '21:00', name: 'timeTo' },
  { value: '22:00', label: '22:00', name: 'timeTo' },
];

export const COUNT_GUEST: SelectOption[] = [
  { value: '1', label: '1 person', name: 'guestCount' },
  { value: '2', label: '2 people', name: 'guestCount' },
  { value: '3', label: '3 people', name: 'guestCount' },
  { value: '4', label: '4 people', name: 'guestCount' },
  { value: '5', label: '5 people', name: 'guestCount' },
  { value: '6', label: '6 people', name: 'guestCount' },
  { value: '7', label: '7 people', name: 'guestCount' },
  { value: '8', label: '8 people', name: 'guestCount' },
  { value: '9', label: '9 people', name: 'guestCount' },
  { value: '10', label: '10 people', name: 'guestCount' },
  { value: '11', label: '11 people', name: 'guestCount' },
];
