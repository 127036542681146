import { IRestaurants } from './restaurants';

export enum SlotActionTypes {
  FETCH_SLOTS = 'FETCH_SLOTS',
  FETCH_SLOTS_SUCCESS = 'FETCH_SLOTS_SUCCESS',
  FETCH_SLOTS_ERROR = 'FETCH_SLOTS_ERROR',
  CHECK_SLOT = 'CHECK_SLOT',
  CHECK_SLOT_SUCCESS = 'CHECK_SLOT_SUCCESS',
  CHECK_SLOT_ERROR = 'CHECK_SLOT_ERROR',
  RESERVATION_SLOTS = 'RESERVATION_SLOTS',
  RESERVATION_SLOTS_SUCCESS = 'RESERVATION_SLOTS_SUCCESS',
  RESERVATION_SLOTS_ERROR = 'RESERVATION_SLOTS_ERROR',
  CLEAR_MESSAGE_SLOTS = 'CLEAR_MESSAGE_SLOTS',
}

export interface ISlot {
  restaurantId: number;
  courses: string[];
  date: string;
  guest: string;
  guestCount: string[];
  id: number;
  operation: string;
  time: string;
  type: string;
}

export interface ISlots {
  dinner?: ISlot[];
  id?: number;
  lunch?: ISlot[];
}

export interface ISlotSelected extends ISlots {
  priority: number;
}

export interface ISlotsResponse {
  restaurantInfo: IRestaurants | null;
  slots: ISlots[] | null;
}

interface IReservedSlot {
  restaurantId: number | undefined;
  priority: number;
  course: string;
  date: string;
  guest: string;
  guestCount: string;
  id: number;
  operation: string;
  time: string;
  type: string;
}

export interface IReservationResponse {
  s?: IReservedSlot;
  r?: IRestaurants;
  message?: string;
  status?: string;
}

export interface SlotState extends ISlotsResponse {
  loading: boolean;
  error: string | null;
  reserved: IReservationResponse | null;
  freeSlot: IReservationResponse | null;
}

interface FetchSlotsAction {
  type: SlotActionTypes.FETCH_SLOTS;
}
interface FetchSlotsSuccessAction {
  type: SlotActionTypes.FETCH_SLOTS_SUCCESS;
  payload: any;
}
interface FetchSlotsErrorAction {
  type: SlotActionTypes.FETCH_SLOTS_ERROR;
  payload: string;
}
interface CheckSlotAction {
  type: SlotActionTypes.CHECK_SLOT;
}
interface CheckSlotSuccessAction {
  type: SlotActionTypes.CHECK_SLOT_SUCCESS;
  payload: any;
}
interface CheckSlotErrorAction {
  type: SlotActionTypes.CHECK_SLOT_ERROR;
  payload: string;
}
interface ReservationSlotsAction {
  type: SlotActionTypes.RESERVATION_SLOTS;
}
interface ReservationSlotsSuccessAction {
  type: SlotActionTypes.RESERVATION_SLOTS_SUCCESS;
  payload: any;
}
interface ReservationSlotsErrorAction {
  type: SlotActionTypes.RESERVATION_SLOTS_ERROR;
  payload: string;
}
interface ClearMessageAction {
  type: SlotActionTypes.CLEAR_MESSAGE_SLOTS;
}

export type SlotAction =
  | FetchSlotsAction
  | FetchSlotsSuccessAction
  | FetchSlotsErrorAction
  | CheckSlotAction
  | CheckSlotSuccessAction
  | CheckSlotErrorAction
  | ReservationSlotsAction
  | ReservationSlotsSuccessAction
  | ReservationSlotsErrorAction
  | ClearMessageAction;
