import {
  RestaurantActionTypes,
  RestaurantState,
  RestaurantAction,
} from '../../types/restaurants';

const initialState: RestaurantState = {
  restaurants: {
    totalItemsCount: 0,
    pageIndex: 0,
    data: [],
  },
  loading: false,
  error: null,
};

export const restaurantReducer = (
  state = initialState,
  action: RestaurantAction
): RestaurantState => {
  switch (action.type) {
    case RestaurantActionTypes.FETCH_RESTAURANTS:
      return {
        loading: true,
        error: null,
        restaurants: {
          totalItemsCount: 0,
          pageIndex: 0,
          data: state.restaurants.data,
        },
      };
    case RestaurantActionTypes.FETCH_RESTAURANTS_SUCCESS:
      return {
        loading: false,
        error: null,
        restaurants: {
          totalItemsCount: action.payload.totalItemsCount,
          pageIndex: action.payload.pageIndex,
          data: action.payload.data,
        },
      };
    case RestaurantActionTypes.FETCH_RESTAURANTS_ERROR:
      return {
        loading: false,
        error: action.payload,
        restaurants: { totalItemsCount: 0, pageIndex: 0, data: [] },
      };
    case RestaurantActionTypes.SEARCH_RESTAURANTS:
      return {
        loading: true,
        error: null,
        restaurants: {
          totalItemsCount: 0,
          pageIndex: 0,
          data: state.restaurants.data,
        },
      };
    case RestaurantActionTypes.SEARCH_RESTAURANTS_SUCCESS:
      return {
        loading: false,
        error: null,
        restaurants: {
          totalItemsCount: action.payload.totalItemsCount,
          pageIndex: action.payload.pageIndex,
          data: action.payload.data,
        },
      };
    case RestaurantActionTypes.SEARCH_RESTAURANTS_ERROR:
      return {
        loading: false,
        error: action.payload,
        restaurants: {
          totalItemsCount: 0,
          pageIndex: 0,
          data: state.restaurants.data,
        },
      };
    default:
      return state;
  }
};
