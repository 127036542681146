import { Dispatch } from 'redux';
import {
  AuthAction,
  AuthActionTypes,
  ILogin,
  IRegistration,
  ISMSConfirmation,
} from '../../types/auth';
import AuthService from '../../services/AuthService';

import { clearAuthData, saveAuthData } from '../../helpers/authStorage';

export const authUser = (isAuth: boolean) => {
  return (dispatch: Dispatch<AuthAction>) => {
    dispatch({ type: AuthActionTypes.USER_IS_AUTH, payload: isAuth });
  };
};
export const registrationUser = (props: IRegistration) => {
  return async (dispatch: Dispatch<AuthAction>) => {
    try {
      dispatch({ type: AuthActionTypes.REGISTRATION_USER });
      const response = await AuthService.registration(props);
      dispatch({
        type: AuthActionTypes.REGISTRATION_USER_SUCCESS,
        payload: !!response.data.userId,
      });
    } catch (error) {
      dispatch({
        type: AuthActionTypes.REGISTRATION_USER_ERROR,
        payload: error.response.data?.message,
      });
    }
  };
};
export const loginUser = ({ email, password }: ILogin) => {
  return async (dispatch: Dispatch<AuthAction>) => {
    try {
      dispatch({ type: AuthActionTypes.LOGIN_USER });
      const response = await AuthService.login(email, password);
      dispatch({
        type: AuthActionTypes.LOGIN_USER_SUCCESS,
        payload: !!response.data.userId,
      });
      saveAuthData(response.data);
    } catch (error) {
      dispatch({
        type: AuthActionTypes.LOGIN_USER_ERROR,
        payload: error.response.data?.message,
      });
    }
  };
};
export const logoutUser = () => {
  return async (dispatch: Dispatch<AuthAction>) => {
    try {
      dispatch({ type: AuthActionTypes.LOGOUT_USER });
      clearAuthData();
      dispatch({ type: AuthActionTypes.LOGOUT_USER_SUCCESS });
    } catch (error) {
      dispatch({
        type: AuthActionTypes.LOGOUT_USER_ERROR,
        payload: error.message,
      });
    }
  };
};

export const smsConfirmations = ({
  phoneCountry,
  phoneLocalNumber,
}: ISMSConfirmation) => {
  return async (dispatch: Dispatch<AuthAction>) => {
    try {
      dispatch({ type: AuthActionTypes.SMS_CONFIRMATIONS });
      const response = await AuthService.smsConfirmations(
        phoneCountry,
        phoneLocalNumber
      );
      dispatch({
        type: AuthActionTypes.SMS_CONFIRMATIONS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: AuthActionTypes.SMS_CONFIRMATIONS_ERROR,
        payload: error.message,
      });
    }
  };
};

export const clearMessageAuth = () => {
  return (dispatch: Dispatch<AuthAction>) => {
    dispatch({ type: AuthActionTypes.CLEAR_MESSAGE_AUTH });
  };
};
