import React, { FC, useState } from 'react';
/* import { Link } from 'react-router-dom'; */

import Logo from '../../../components/Logo';
import InputField from '../../../components/Form/InputField';
import CheckBox from '../../../components/Form/CheckBox';
import Button from '../../../components/Form/Button';
import Alert from '../../../components/Alert';

import { saveUserData, getUserData } from '../../../helpers/rememberMe';

import classes from './styles.module.scss';
import { useTypesSelector } from '../../../hooks/useTypesSelector';

import { ILogin } from '../../../types/auth';
import { useActions } from '../../../hooks/useActions';

const LoginPage: FC = () => {
  const { loading, error } = useTypesSelector((state) => state.auth);
  const { loginUser, clearMessageAuth } = useActions();
  const { rememberMe } = getUserData();
  const [state, setState] = useState<ILogin>({
    email: '',
    password: '',
  });

  const [remember, setRemember] = useState<boolean>(rememberMe);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const login = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    saveUserData({
      email: state.email,
      rememberMe: remember,
    });

    loginUser(state);
  };

  return (
    <>
      <div className={classes.LoginPage}>
        <div className={classes.wrapp}>
          <Logo />
          <div className={classes.form}>
            <form action="">
              <div className={classes.inputsContainer}>
                <InputField
                  topField
                  name="email"
                  type="text"
                  value={state.email}
                  setValue={onChange}
                  placeholder="Email address"
                />
                <InputField
                  bottomField
                  type="password"
                  name="password"
                  value={state.password}
                  setValue={onChange}
                  placeholder="Password"
                />
              </div>
              <div className={classes.checkBoxContainer}>
                <CheckBox
                  label="Remember me?"
                  checked={remember}
                  name="rememberMe"
                  onChange={() => setRemember(!remember)}
                />
              </div>
              <div className={classes.btnContainer}>
                <Button
                  isLoading={loading}
                  isLogin
                  handler={login}
                  label="Sign in"
                  isDisabled={!(state.email && state.password) || loading}
                />
              </div>
            </form>
            {/* <div className={classes.linkContainer}>
              <Link to="/signup" className={classes.link}>
                Create an account
              </Link>
            </div> */}
          </div>
        </div>
      </div>
      <Alert message={error} clearMessage={clearMessageAuth} />
    </>
  );
};

export default LoginPage;
