import React, { FC, useEffect, useState } from 'react';

import moment from 'moment';
import classnames from 'classnames';

import classes from './styles.module.scss';
import { SlotType } from '../PlacesList';
import { ISlots, ISlot, ISlotSelected } from '../../types/slots';

interface IPlaceItem {
  place: ISlots;
  changeCounter(): void;
  counter: number;
  selectedSlot(slotDay: ISlotSelected): void;
  reservation: SlotType;
}

const PlacesItem: FC<IPlaceItem> = ({
  place,
  changeCounter,
  counter,
  selectedSlot,
  reservation,
}) => {
  const { id, lunch, dinner } = place;
  const [select, setSelect] = useState(false);
  const [priority, setPriority] = useState<number>(0);
  const isSelected = reservation.find((item) => item.id === id);
  const selectItem = () => {
    if (!select) {
      changeCounter();
    }
    setSelect(true);
  };

  useEffect(() => {
    if (select) {
      setPriority(counter);
    }
  }, [select]);

  useEffect(() => {
    if (priority) {
      selectedSlot({
        id,
        priority,
        ...place,
      });
    }
  }, [priority]);

  const courseGenerate = (l: ISlot[], d: ISlot[]) => {
    const firstItemLunch = l[0];
    const firstItemDinner = d[0];
    const courseLunch = firstItemLunch.courses[0];
    const courseDinner = firstItemDinner.courses[0];
    const [lunchTitle, lunchPrice] = courseLunch.split(',');
    const [dinnerTitle, dinnerPrice] = courseDinner.split(',');

    if (lunchTitle === dinnerTitle && lunchPrice === dinnerPrice) {
      return `${lunchTitle} (${lunchPrice}JPY / person)`;
    }
    return `${lunchTitle} (${lunchPrice}JPY / person)
    / ${dinnerTitle} (${dinnerPrice}JPY / person)`;
  };

  const getDate = (meal: any) => {
    const firstItem = meal[0];
    return moment(firstItem.date).format('YYYY/MM/DD ddd');
  };

  const getInfoTwoType = (l: ISlot[], d: ISlot[], option: string) => {
    const firstItemLunch: any = l[0];
    const firstItemDinner: any = d[0];
    if (option === 'courses') {
      return courseGenerate(l, d);
    }
    return `${firstItemLunch[option]} / ${firstItemDinner[option]}`;
  };

  const getInfo = (meal: ISlots, option: string) => {
    if (meal.lunch?.length) {
      const firstItemLunch: any = meal.lunch[0];
      if (option === 'courses') {
        const courseLunch = firstItemLunch.courses[0];
        const [lunchTitle, lunchPrice] = courseLunch.split(',');
        return `${lunchTitle} (${lunchPrice}JPY / person)`;
      }
      return `${firstItemLunch[option]}`;
    }
    const firstItemDinner: any = meal?.dinner ? meal.dinner[0] : {};
    if (option === 'courses') {
      const courseDinner = firstItemDinner.courses[0];
      const [dinnerTitle, dinnerPrice] = courseDinner.split(',');
      return `${dinnerTitle} (${dinnerPrice}JPY / person)`;
    }
    return `${firstItemDinner[option]}`;
  };

  return (
    <>
      <tr
        className={classnames(
          classes.tableRow,
          select && isSelected && classes.selectRow
        )}
        onClick={selectItem}
        key={place.id}
      >
        <td className={classes.td}>{isSelected ? priority : ''}</td>
        <td className={classes.td}>
          {lunch?.length ? getDate(lunch) : getDate(dinner)}
        </td>
        <td className={classes.td}>
          {lunch?.length && dinner?.length
            ? getInfoTwoType(lunch, dinner, 'time')
            : getInfo(place, 'time')}
        </td>
        <td className={classes.td}>
          {lunch?.length && dinner?.length
            ? getInfoTwoType(lunch, dinner, 'guest')
            : getInfo(place, 'guest')}
        </td>
        <td className={classes.td}>
          {lunch?.length && dinner?.length
            ? getInfoTwoType(lunch, dinner, 'courses')
            : getInfo(place, 'courses')}
        </td>
      </tr>
    </>
  );
};

export default PlacesItem;
