import { AxiosResponse } from 'axios';
import { SlotType, ISlotInfo } from '../components/PlacesList';
import $api from '../http';
import { IRestaurant, ISearchQuery } from '../types/restaurants';
import { ISlotsResponse, IReservationResponse } from '../types/slots';

export default class RestaurantService {
  static fetchRestaurants(
    props: ISearchQuery
  ): Promise<AxiosResponse<IRestaurant>> {
    return $api.get<IRestaurant>('/restaurants', {
      params: { ...props },
    });
  }

  static searchRestaurants(
    props: ISearchQuery
  ): Promise<AxiosResponse<IRestaurant>> {
    return $api.get<IRestaurant>('/restaurants/search', {
      params: { ...props },
    });
  }

  static fetchRestaurant(id: string): Promise<AxiosResponse<ISlotsResponse>> {
    return $api.get<ISlotsResponse>(`/restaurants/${id}`);
  }

  static checkFreeSlot(
    slot: ISlotInfo
  ): Promise<AxiosResponse<IReservationResponse>> {
    return $api.post<IReservationResponse>(`/restaurants/check-slot`, {
      slot,
    });
  }

  static makeReservation(
    reservation: SlotType,
    password: string
  ): Promise<AxiosResponse<IReservationResponse>> {
    return $api.post<IReservationResponse>(`/restaurants/places`, {
      reservation,
      password,
    });
  }
}
