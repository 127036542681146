import React, { FC } from 'react';
import Timer from '../Timer';

import classes from './styles.module.scss';

interface ITitle {
  name?: string | null;
  time?: any;
  stop?: boolean;
  reserved?: any;
  error?: any;
}

const Title: FC<ITitle> = ({ name = '', time = '', stop, reserved, error }) => {
  return (
    <div className={classes.titleContainer}>
      <div className={classes.title}>{name}</div>
      {!stop && !reserved && !error ? (
        <div className={classes.timer}>
          <Timer expiryTimestamp={time} stop={stop} />
        </div>
      ) : null}
    </div>
  );
};

export default Title;
