import { SlotActionTypes, SlotState, SlotAction } from '../../types/slots';

const initialState: SlotState = {
  restaurantInfo: null,
  slots: null,
  freeSlot: null,
  reserved: null,
  loading: false,
  error: null,
};

export const slotReducer = (
  state = initialState,
  action: SlotAction
): SlotState => {
  switch (action.type) {
    case SlotActionTypes.FETCH_SLOTS:
      return {
        loading: true,
        error: null,
        restaurantInfo: null,
        slots: null,
        reserved: null,
        freeSlot: null,
      };
    case SlotActionTypes.FETCH_SLOTS_SUCCESS:
      return {
        loading: false,
        error: null,
        restaurantInfo: action.payload.restaurantInfo,
        slots: action.payload.slots,
        reserved: null,
        freeSlot: null,
      };
    case SlotActionTypes.FETCH_SLOTS_ERROR:
      return {
        loading: false,
        error: action.payload,
        restaurantInfo: null,
        slots: null,
        reserved: null,
        freeSlot: null,
      };
    case SlotActionTypes.CHECK_SLOT:
      return {
        loading: false,
        error: null,
        restaurantInfo: state.restaurantInfo,
        slots: state.slots,
        reserved: null,
        freeSlot: null,
      };
    case SlotActionTypes.CHECK_SLOT_SUCCESS:
      return {
        loading: false,
        error: null,
        restaurantInfo: state.restaurantInfo,
        slots: state.slots,
        reserved: null,
        freeSlot: action.payload,
      };
    case SlotActionTypes.CHECK_SLOT_ERROR:
      return {
        loading: false,
        error: action.payload,
        restaurantInfo: state.restaurantInfo,
        slots: state.slots,
        reserved: null,
        freeSlot: null,
      };
    case SlotActionTypes.RESERVATION_SLOTS:
      return {
        loading: true,
        error: null,
        restaurantInfo: state.restaurantInfo,
        slots: state.slots,
        reserved: null,
        freeSlot: null,
      };
    case SlotActionTypes.RESERVATION_SLOTS_SUCCESS:
      return {
        loading: false,
        error: null,
        restaurantInfo: state.restaurantInfo,
        slots: state.slots,
        reserved: action.payload,
        freeSlot: null,
      };
    case SlotActionTypes.RESERVATION_SLOTS_ERROR:
      return {
        loading: false,
        error: action.payload,
        restaurantInfo: state.restaurantInfo,
        slots: state.slots,
        reserved: null,
        freeSlot: null,
      };
    case SlotActionTypes.CLEAR_MESSAGE_SLOTS:
      return {
        loading: false,
        error: null,
        restaurantInfo: state.restaurantInfo,
        slots: state.slots,
        reserved: null,
        freeSlot: null,
      };
    default:
      return state;
  }
};
