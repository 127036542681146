import React, { FC, useEffect, useState } from 'react';

import moment from 'moment';
import Button from '../Button';

import classes from './styles.module.scss';

interface ISlotInfo {
  id: number;
  date: string;
  time: string;
  guest: string;
  operation: string;
  type: string;
  guestCount: string[];
  courses: string[];
}

interface IPlaceReserve {
  date: string;
  time: string;
  operation: string;
  type: string;
  guestCount: string;
  course: string;
  priority: number;
  id: number;
}

interface IFormSlot {
  slot: any;
  onClose(): void;
  saveSlotItem(slot: IPlaceReserve): void;
}

type CountGuestType = string[] | null;
type CoursesType = string[];

const guests: string = localStorage.getItem('guestCount') || '';

const FormSlot: FC<IFormSlot> = ({ slot, onClose, saveSlotItem }) => {
  const { id, priority, lunch, dinner } = slot;
  const [countGuest, setCountGuest] = useState<CountGuestType>(null);
  const [courses, setCourses] = useState<CoursesType>([]);
  const [selectCourse, setSelectCourse] = useState('');
  const [placeReserve, setPlaceReserve] = useState({
    date: '',
    time: '',
    operation: '',
    type: '',
    guestCount: guests,
    course: '',
    priority,
    id,
  });
  const getDate = (meal: any) => {
    const firstItem = meal[0];
    return moment(firstItem.date).format('YYYY/MM/DD ddd');
  };

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const strTypeMeal = e.target.value;
    const lastSpace = strTypeMeal.lastIndexOf(' ');
    const type = strTypeMeal.slice(0, lastSpace);
    const meal = strTypeMeal.slice(lastSpace).trim();

    const slotMeal = slot[meal];
    const findItem = slotMeal.find((item: ISlotInfo) => item.type === type);

    const data = {
      date: findItem.date,
      time: findItem.time,
      operation: findItem.operation,
      type: findItem.type,
      restaurantId: findItem.restaurantId,
      course: '',
    };

    if (findItem.courses.length === 1) {
      const [title, price] = findItem.courses.join('').split(',');
      data.course = `${title},${price}`;
      setSelectCourse(`${title},${price}`);
    }

    setPlaceReserve((prev) => ({
      ...prev,
      ...data,
    }));

    setCountGuest(['', ...findItem.guestCount]);
    setCourses(findItem.courses);
  };
  const changeHandlerCourse = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectCourse(e.target.value);
    setPlaceReserve((prev) => ({
      ...prev,
      course: e.target.value,
    }));
  };

  const selectCountHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    localStorage.setItem('guestCount', e.target.value);
    setPlaceReserve((prev) => ({
      ...prev,
      guestCount: e.target.value,
    }));
  };

  const onSave = () => {
    onClose();
    saveSlotItem(placeReserve);
  };

  return (
    <div className={classes.FormSlot}>
      <div className={classes.title}>
        {lunch.length ? getDate(lunch) : getDate(dinner)}
      </div>
      {lunch.length ? (
        <div className={classes.selectItem}>
          <div className={classes.titleMeal}>Lunch</div>
          <div>
            <ul className={classes.listContainer}>
              {lunch.map((item: ISlotInfo, index: number) => (
                <li key={item.id}>
                  <div>
                    <label
                      className={classes.container}
                      htmlFor={`${index}lunch`}
                    >
                      <span className={classes.label}>
                        {item.time} {item.type} {item.guest}
                      </span>
                      <input
                        type="radio"
                        id={`${index}lunch`}
                        name="item-name"
                        value={`${item.type} lunch`}
                        onChange={changeHandler}
                      />
                      <span className={classes.checkmark} />
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : null}
      {dinner.length ? (
        <div className={classes.selectItem}>
          <div className={classes.titleMeal}>Dinner</div>
          <div>
            <ul className={classes.listContainer}>
              {dinner.map((item: ISlotInfo, index: number) => (
                <li key={item.id}>
                  <div>
                    <label
                      className={classes.container}
                      htmlFor={`${index}dinner`}
                    >
                      <span className={classes.label}>
                        {item.time} {item.type} {item.guest}
                      </span>
                      <input
                        type="radio"
                        id={`${index}dinner`}
                        name="item-name"
                        value={`${item.type} dinner`}
                        onChange={changeHandler}
                      />
                      <span className={classes.checkmark} />
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : null}
      {countGuest && countGuest.length ? (
        <div className={classes.numberGuest}>
          <div className={classes.numberGuestTitle}>Number of guests</div>
          <div>
            <select
              name="countGuest"
              className={classes.numberSelect}
              onChange={selectCountHandler}
            >
              {countGuest.map((item) => (
                <option
                  value={item}
                  key={item}
                  selected={
                    localStorage.getItem('guestCount') === item ? !!item : false
                  }
                >
                  {item}
                </option>
              ))}
            </select>
          </div>
        </div>
      ) : null}
      {countGuest && countGuest.length ? (
        <div className={classes.numberGuest}>
          <div className={classes.numberGuestTitle}>Courses</div>
          <div>
            <ul className={classes.listContainer}>
              {courses.map((item, index) => {
                const [title, price] = item.split(',');
                const idLi = `${index}course`;
                return (
                  <li key={idLi}>
                    <div>
                      <label
                        className={classes.container}
                        htmlFor={`${index}course`}
                      >
                        <span className={classes.label}>
                          {title} {price}JPY / person
                        </span>
                        <input
                          type="radio"
                          id={`${index}course`}
                          name="course"
                          value={`${title},${price}`}
                          onChange={changeHandlerCourse}
                          checked={courses.length === 1 ? true : undefined}
                        />
                        <span className={classes.checkmark} />
                      </label>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      ) : null}
      <div className={classes.btnContainer}>
        <Button
          isContinue
          handler={onSave}
          label="Continue"
          isDisabled={!(placeReserve.guestCount && selectCourse)}
        />
      </div>
    </div>
  );
};

export default FormSlot;
