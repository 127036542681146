import React, { FC, useState } from 'react';

import Place from '../Place';
import { Modal } from '../Modal';
import FormSlot from '../Form/FormSlot';
import FormConfirmPassword from '../Form/FormConfirmPassword';
import Button from '../Form/Button';

import classes from './styles.module.scss';
import { ISlots, ISlotSelected } from '../../types/slots';

export interface IConfirmPassword {
  password: string;
}

export interface ISlotInfo {
  restaurantId: number;
  course: string;
  date: string;
  guestCount: string;
  id: number;
  operation: string;
  priority: number;
  time: string;
  type: string;
}

export type SlotType = ISlotInfo[] | [];

export interface IReservation {
  reservation: SlotType;
  password: string;
}

type SelectedSlotType = ISlotSelected | null;

interface IPlaceList {
  places: ISlots[];
  checkSlot(slot: ISlotInfo): void;
}

const PlacesList: FC<IPlaceList> = ({ places, checkSlot }) => {
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(0);
  const [slot, setSlot] = useState<SelectedSlotType>(null);
  const [reservation, setReservation] = useState<SlotType>([]);

  const changeCounter = () => {
    setCounter(counter + 1);
  };

  const selectedSlot = (slotDay: ISlotSelected) => {
    setSlot(slotDay);
    setOpenCreateModal(true);
  };

  const saveSlot = (slotItem: ISlotInfo) => {
    setReservation((prev) => [...prev, slotItem]);
    checkSlot(slotItem);
  };

  const cancel = () => {
    setCounter(counter - 1);
    setOpenCreateModal(false);
  };

  return (
    <div className={classes.PlacesList}>
      <table className={classes.table}>
        <thead>
          <tr className={classes.tableHeader}>
            <th className={classes.th}> </th>
            <th className={classes.th}>Date</th>
            <th className={classes.th}>Time</th>
            <th className={classes.th}>Guests</th>
            <th className={classes.th}>Cource</th>
          </tr>
        </thead>
        <tbody>
          {places.map((item) => (
            <Place
              key={item.id}
              place={item}
              counter={counter}
              changeCounter={changeCounter}
              selectedSlot={selectedSlot}
              reservation={reservation}
            />
          ))}
        </tbody>
      </table>
      <Modal open={openCreateModal} onClose={cancel}>
        <FormSlot
          saveSlotItem={saveSlot}
          slot={slot}
          onClose={() => setOpenCreateModal(false)}
        />
      </Modal>
    </div>
  );
};

export default PlacesList;
