import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import moment from 'moment';
import classnames from 'classnames';

import { useActions } from '../../hooks/useActions';
import { useTypesSelector } from '../../hooks/useTypesSelector';

import Header from '../../components/Header';
import Loader from '../../components/Loader';
import Title from '../../components/Title';
import FormConfirmPassword from '../../components/Form/FormConfirmPassword';
import { Modal } from '../../components/Modal';

import classes from './styles.module.scss';

import { IConfirmPassword } from '../../components/PlacesList';

const PlaceDetailPage = () => {
  const history = useHistory();
  const time = new Date();
  time.setSeconds(time.getSeconds() + 300);
  const { loading, freeSlot, error, reserved } = useTypesSelector(
    (state) => state.slot
  );

  const [openConfirmPasswordModal, setOpenPasswordConfirm] =
    useState<boolean>(false);

  const { createReservation } = useActions();

  const generateCourse = (course: string) => {
    const [title, price] = course.split(',');
    return `${title} (${price}JPY / person)`;
  };

  const makeReserve = (password: IConfirmPassword) => {
    const slot: any = { ...freeSlot?.s };

    createReservation({
      reservation: [{ ...slot }],
      ...password,
    });
  };

  const cancelConfirm = () => {
    setOpenPasswordConfirm(false);
  };

  return (
    <>
      <div className={classes.PlaceDetailPage}>
        <Header />
        <Title
          name={freeSlot && freeSlot.r?.name}
          time={time}
          stop={loading}
          reserved={reserved}
          error={error}
        />
        {loading ? (
          <Loader />
        ) : (
          <>
            {freeSlot && freeSlot.s ? (
              <div className={classes.placeDetail}>
                <div className={classes.subDetail}>
                  <div>
                    {moment(freeSlot.s.date).format('YYYY/MM/DD')}
                    {` ${freeSlot.s.time}`}
                  </div>
                  <div>
                    {freeSlot.s.guestCount}
                    {+freeSlot.s.guestCount === 1 ? ' person' : ' peoples'}
                  </div>
                </div>
                <div className={classes.detail}>
                  <div>{generateCourse(freeSlot.s.course)}</div>
                </div>
                <div className={classes.btnContainer}>
                  <button
                    type="button"
                    className={classes.btn}
                    onClick={() => setOpenPasswordConfirm(true)}
                  >
                    Complete reservation
                  </button>
                  <button
                    type="button"
                    className={classnames(classes.btn, classes.btnCancel)}
                    onClick={() => history.push('/')}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <>
                {reserved && reserved.status ? (
                  <div className={classes.message}>{reserved.status}</div>
                ) : (
                  <div className={classes.message}>{error || null}</div>
                )}
              </>
            )}
          </>
        )}
      </div>
      <Modal open={openConfirmPasswordModal} onClose={cancelConfirm}>
        <FormConfirmPassword
          makeReserve={makeReserve}
          onClose={() => setOpenPasswordConfirm(false)}
        />
      </Modal>
    </>
  );
};

export default PlaceDetailPage;
