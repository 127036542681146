import React, { FC } from 'react';

import classnames from 'classnames';

import classes from './styles.module.scss';

interface ILogo {
  miniLogo?: boolean;
}

const Logo: FC<ILogo> = ({ miniLogo }) => {
  return (
    <div className={classnames(classes.logo, miniLogo && classes.miniLogo)}>
      M
    </div>
  );
};

export default Logo;
