import React, { FC, useState } from 'react';

import Button from '../Button';

import classes from './styles.module.scss';

interface IConfirmPassword {
  password: string;
}

interface IFormConfirm {
  onClose(): void;
  makeReserve(password: IConfirmPassword): void;
}

const FormConfirmPassword: FC<IFormConfirm> = ({ onClose, makeReserve }) => {
  const [state, setState] = useState({
    password: '',
  });
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const continuePassword = () => {
    makeReserve(state);
    onClose();
  };
  return (
    <div className={classes.FormConfirmPassword}>
      <div className={classes.title}>Confirm password</div>
      <div className={classes.passwordContainer}>
        <input
          type="password"
          value={state.password}
          onChange={onChange}
          placeholder="Please enter password"
          name="password"
          className={classes.passwordField}
        />
      </div>
      <div className={classes.btnContainer}>
        <Button
          isContinue
          handler={continuePassword}
          label="Continue"
          isDisabled={!state.password}
        />
      </div>
    </div>
  );
};

export default FormConfirmPassword;
